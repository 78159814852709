import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import trackEvents from '../constants/track-events'
import {
  useLanguageContext,
  useNotificationContext,
  usePartnerContext,
  useUserContext
} from '../context'
import { query } from '../graphql'
import { trackEvent } from '../helpers/analytics'

export const useSampleData = () => {
  const { t } = useTranslation()
  const { partner } = usePartnerContext()
  const { success, alert } = useNotificationContext()
  const { language } = useLanguageContext()
  const {
    cognitoUser: { username }
  } = useUserContext()

  const [isLoading, setIsLoading] = useState(false)

  const queryClient = useQueryClient()

  const actionNotPossible = () => {
    const { title, message, action } = {
      title: { de: 'Hinweis', en: 'Notice' }[language],
      message: {
        de: 'Diese Funktion steht für den Partner mit der ID 5403bdbe-d051-4295-98d0-2a7e32da9eba nicht zur Verfügung, die Demodaten werden von diesem Partner übernommen.',
        en: 'This function is not available for the partner with the ID 5403bdbe-d051-4295-98d0-2a7e32da9eba, the demo data is taken from this partner'
      }[language],
      action: { de: 'Alles klar', en: 'Got it' }[language]
    }

    alert(title, message, [{ text: action }])
  }

  const refetchCareers = () => {
    return queryClient.refetchQueries({
      queryKey: ['careers', partner.id]
    })
  }

  const removeTalentsQuery = () => {
    return queryClient.removeQueries({
      queryKey: ['talents', partner.id, username]
    })
  }

  const isAivyDevelopmentPartner = (partner_id) => {
    return partner_id === '5403bdbe-d051-4295-98d0-2a7e32da9eba'
  }

  const deleteSampleData = async ({ onSuccess = undefined }) => {
    if (isAivyDevelopmentPartner(partner.id)) {
      actionNotPossible()
      return
    }

    setIsLoading(true)
    trackEvent(trackEvents.DELETE_DEMO_DATA)

    await query({
      query: 'helperControl',
      variables: { action: 'deleteDemoData' }
    })

    await refetchCareers()
    removeTalentsQuery()

    if (onSuccess) onSuccess()
    else success(t('pages.careers.delete_sample_data_success'))

    setIsLoading(false)
  }

  const createSampleData = async () => {
    if (isAivyDevelopmentPartner(partner.id)) {
      actionNotPossible()
      return
    }

    setIsLoading(true)
    trackEvent(trackEvents.CREATE_DEMO_DATA)

    await query({
      query: 'helperControl',
      variables: { action: 'generateDemoData', language }
    })

    await refetchCareers()
    removeTalentsQuery()

    success(t('pages.careers.create_sample_data_success'))
    setIsLoading(false)
  }

  return {
    isLoading,
    createSampleData,
    deleteSampleData,
    isAivyDevelopmentPartner
  }
}
