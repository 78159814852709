import { Switch } from '@headlessui/react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { useFormik } from 'formik'
import { Fragment, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'

import PropTypes from 'prop-types'
import {
  useAivyContext,
  useLanguageContext,
  useNotificationContext
} from '../../../context'
import { notifyBugsnag } from '../../../helpers'
import { useSetState } from '../../../hooks/use-set-state'
import ChallengeInfoModal from '../../challenge-info-modal'
import Button from '../../tailwind/Button'
import SlideOverDimensionDescription from '../../team-analyse/SlideOverDimensionDescription'

const I18N_PREFIX = 'create_career.must_criteria_selection.'

const MustCriteriaSelection = ({ career, refetchCareer, careerMutation }) => {
  const { t } = useTranslation()
  const { success, error } = useNotificationContext()

  const parsedCareerAnalyse = useMemo(() => {
    try {
      return JSON.parse(career.career_analyse)
    } catch {
      return {}
    }
  }, [career.career_analyse])

  const { scores, override_defaults } = parsedCareerAnalyse

  const { dimensions } = override_defaults

  const { system } = useAivyContext()
  const { language } = useLanguageContext()

  const initialMandatoryCriterias = useMemo(() => {
    if (override_defaults?.mandatory_criteria) {
      return override_defaults?.mandatory_criteria
    }

    return Object.assign(
      ...dimensions
        .filter(
          (d) =>
            d !== 'HEROS_JOURNEY_score_coopcomp' &&
            d !== 'HEROS_JOURNEY_score_orderchaos' &&
            d !== 'TOWER_OF_LONDON_score' &&
            d !== 'FLANKER_score'
        )
        .map((d) => ({ [d]: scores[d]?.mandatoryCriterion === true }))
    )
  }, [dimensions, scores, override_defaults])

  // use FLANKER or other challenges with visible for the modal transition
  const [infoModalChallenge, setInfoModalChallenge] = useSetState({
    ...system.challenges.FLANKER,
    visible: false
  })

  const [openDimensionDescription, setOpenDimensionsDescription] =
    useState(false)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { criteria: initialMandatoryCriterias },
    onSubmit: async ({ criteria }, { setSubmitting }) => {
      try {
        const mandatory_criteria = criteria

        const updatedOverrideDefaults = { mandatory_criteria }
        const beforeOverrideDefaults =
          parsedCareerAnalyse.override_defaults || {}
        const mergedOverrideDefaults = {
          ...beforeOverrideDefaults,
          ...updatedOverrideDefaults
        }

        parsedCareerAnalyse.override_defaults = mergedOverrideDefaults

        await careerMutation.mutate({
          input: {
            id: career.id,
            career_analyse: JSON.stringify(parsedCareerAnalyse)
          }
        })

        await refetchCareer()
        success(t(I18N_PREFIX + 'success_msg'))
      } catch (err) {
        error(t(I18N_PREFIX + 'error_msg'))
        notifyBugsnag(err)
      } finally {
        setSubmitting(false)
      }
    }
  })

  const isSubmitButtonDisabled = !formik.dirty || formik.isSubmitting

  const handleToggle = (array, value) => {
    if (array.includes(value)) {
      return array.filter((item) => item !== value) // Entfernen
    } else {
      return [...array, value] // Hinzufügen
    }
  }

  return (
    <>
      <h2 className='text-2xl/7 font-bold text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight'>
        {t(I18N_PREFIX + 'title')}
      </h2>
      <ReactMarkdown className='mt-2 max-w-2xl text-sm/6 text-gray-900'>
        {t(I18N_PREFIX + 'description')}
      </ReactMarkdown>
      <div className='mt-6'>
        {dimensions
          .filter((d) => system.dimensions_pdf_reports[d].category === 2)
          .map((d) => {
            const dimensionFromSystem = system.dimensions_pdf_reports[d]

            const challenges = dimensionFromSystem.measured_by.map(
              (exam_id) => system.challenges[exam_id]
            )

            return (
              <div
                key={d}
                className='mb-2 flex flex-row items-center justify-between rounded-lg border border-slate-100 bg-slate-50 p-4'
              >
                <div>
                  <span
                    onClick={() => {
                      setOpenDimensionsDescription(dimensionFromSystem)
                    }}
                    className='flex cursor-pointer items-center font-semibold hover:opacity-70'
                  >
                    {dimensionFromSystem.property[language]}
                    <InformationCircleIcon className='ml-1 inline-block h-6 w-6 text-gray-900' />
                  </span>
                  <div className='mt-1' />
                  <span className='text-sm font-normal'>
                    {t(I18N_PREFIX + 'measured_by')}
                    {challenges.map((challenge, index) => (
                      <Fragment key={index}>
                        <span
                          className='cursor-pointer text-sm font-semibold text-blue-600 underline'
                          onClick={() => {
                            setInfoModalChallenge({
                              ...challenge,
                              visible: true
                            })
                          }}
                        >
                          {challenge.title[language]}
                        </span>
                        <span className='last:hidden'>{', '}</span>
                      </Fragment>
                    ))}
                  </span>
                </div>
                <Switch
                  checked={formik.values.criteria.includes(d)}
                  onChange={() => {
                    formik.setFieldValue(
                      'criteria',
                      handleToggle(formik.values.criteria, d)
                    )
                  }}
                  className='group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 data-[checked]:bg-blue-600'
                >
                  <span
                    aria-hidden='true'
                    className='pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5'
                  />
                </Switch>
              </div>
            )
          })}
      </div>
      <div className='mt-12 flex justify-end'>
        <Button.PrimaryLG
          text={t('create_career.hardness_of_selection.cta_save')}
          onClick={() => {
            formik.handleSubmit()
            window.scrollTo({ top: 0, behavior: 'smooth' })
          }}
          disabled={isSubmitButtonDisabled}
          className={`${isSubmitButtonDisabled ? 'opacity-50' : ''}`}
          isLoading={formik.isSubmitting}
        />
      </div>
      <SlideOverDimensionDescription
        open={openDimensionDescription}
        setOpen={setOpenDimensionsDescription}
      />
      <ChallengeInfoModal
        open={infoModalChallenge.visible}
        setOpen={() => setInfoModalChallenge({ visible: false })}
        challenge={infoModalChallenge}
      />
    </>
  )
}

MustCriteriaSelection.propTypes = {
  career: PropTypes.object.isRequired,
  careerMutation: PropTypes.object.isRequired,
  refetchCareer: PropTypes.func.isRequired
}

export default MustCriteriaSelection
